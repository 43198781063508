import React from "react"
import { graphql } from "gatsby"
import Picture from "gatsby-image"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ParkingPage = ({ data }) => {
  return (
    <Layout>
      <h1>Parking</h1>
      <div style={{ float: `left`, maxWidth: `800px` }}>
        <h2>Bicycles</h2>
        <hr />
        <p>
          There are bike sheds available in all areas, including two internal
          ones, for your bike storage. Please do not keep bikes in the
          stairwells as they are a fire and safety hazard and will be removed!
          Contact Trinity Factors if you require access to a bike shed.
        </p>
        <p
          style={{
            maxWidth: `500px`,
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <Picture
            className="calevillage-bike-shed.jpg"
            fluid={data.file.childImageSharp.fluid}
            alt="calevillage-bike-shed.jpg"
          />
        </p>

        <h2>Car Parking</h2>
        <hr />
        <h3>Resident Parking &amp; Permits</h3>
        <p>
          A Caledonian Village parking permit is required for parking within all/any of our residental
          parking areas. These areas, <a href="/parking/areas">located behind and to the side of the
          various blocks</a>, are patrolled by <OutboundLink href="https://p4parking.co.uk">P4 Parking</OutboundLink>.
        </p>

        <p>
          Initial permits are delivered to each flat or posted out to an alternative address if specifcally requested around the time of renewal. Thereafter owners
          are responsible for making their permit available to tenants or subsequent owners. Please read our <OutboundLink href="https://drive.google.com/file/d/1r6EcmWSWR_kHqrZv2BWUaqYb9GaL15QD/view?usp=share_link">Permit Policy</OutboundLink> for further details of how to apply for an additional or replacement permits. Please note that only owners are eligible to apply for permits, not property managers or tenants. Tenants in the first instance should contact their landlord or letting agent. The <a href="/parking/guidance">parking permit application guidance</a> contains a couple of examples to help assist owners when submitting an application. 
        </p>

        <h3>Other Areas</h3>

        <p>
          Please raise cases of vehicles parking on double yellow lines directly
          with Edinburgh Council for enforcement.
        </p>

        <blockquote>
          <ul>
            <li>
              <OutboundLink href="https://www.edinburgh.gov.uk/incorrectlyparked">
                Report incorrectly parked vehicles
              </OutboundLink>
            </li>
          </ul>
        </blockquote>
        <p>
          Alternatively if you feel a vehicle is parked badly, or causing a
          hazard to others, then Police Scotland may be able to help if
          contacted on their non-emergency number (101).
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "images/calevillage-bike-shed.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ParkingPage
